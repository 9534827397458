import consumer from "./consumer";
class FileReaderEx extends FileReader {
  constructor() {
    super();
  }

  readAs(blob, ctx) {
    return new Promise((res, rej) => {
      super.addEventListener("load", ({ target }) => res(target.result));
      super.addEventListener("error", ({ target }) => rej(target.error));
      super[ctx](blob);
    });
  }

  readAsArrayBuffer(blob) {
    return this.readAs(blob, "readAsArrayBuffer");
  }

  readAsDataURL(blob) {
    return this.readAs(blob, "readAsDataURL");
  }

  readAsText(blob) {
    return this.readAs(blob, "readAsText");
  }
}

window.addEventListener("DOMContentLoaded", () => {
  const sendMessageButton = document.querySelector("#sendMessage");

  if (sendMessageButton) {
    const roomId = sendMessageButton.dataset.roomid;
    const messageInput = document.querySelector("#messageInput");

    const chatChannel = consumer.subscriptions.create(
      { channel: "RoomChannel", room_id: roomId },
      {
        connected() {
          // Called when the subscription is ready for use on the server
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
        },

        received(data) {
          // Called when there's incoming data on the websocket for this channel
          // return alert(data["message"]);
          sendMessageButton.classList.remove("disabled");
          sendMessageButton.innerText = "送信";

          const messageContainer = document.querySelector("#messages");
          if (messageContainer) {
            return messageContainer.insertAdjacentHTML(
              "beforeend",
              data["message"]
            );
          } else {
            console.error("no message container found.");
          }
        },

        speak: function (message, roomId, imageFileName, imageBlob) {
          return this.perform("speak", {
            message: message,
            room_id: roomId,
            image_filename: imageFileName,
            image_blob: imageBlob,
          });
        },
      }
    );

    sendMessageButton.addEventListener("click", async function (event) {
      messageInput.focus();
      if (messageInput.value === "") return;
      let imageBlob;
      let imageFileName;

      if (document.getElementById("fileInput").files.length > 0) {
        console.log("hoge");
        imageBlob = await new FileReaderEx().readAsDataURL(
          document.getElementById("fileInput").files[0]
        );
        imageFileName = document.getElementById("fileInput").files[0].name;
      }
      chatChannel.speak(messageInput.value, roomId, imageFileName, imageBlob);
      messageInput.value = "";
      document.getElementById("fileInput").value = "";
      sendMessageButton.classList.add("disabled");
      sendMessageButton.innerHTML = "送信中...";

      return event.preventDefault();
    });
  }
});
